import React from "react"
import Header from "../components/header"
import FisherMap from "../components/fisher-map"
import Footer from "../components/footer"
import Explore from "../components/explore"

import story from "../images/story.jpg"
//import news1 from "../images/news1.jpg"
//import news2 from "../images/news2.jpg"

const IndexPage = () => (
  <div id="indexPage">
    <Header/>

    <FisherMap/>

    <div className="content-pad">
      <div className="columns-2 big-margin-bottom">
        <div>
          <h2 style={{textAlign: 'left', margin: '0 0 25px 0'}}>Who we are</h2>
          <p>
	          FISHERY MEDITERRANEAN NETWORK PROJECT FISH MEDNET is a Euro-Mediterranean project aimed at developing the integration and diversification of the fisheries sector by giving it a new dimension. The project is funded by the EU under the ENI CBC Med Programme.
          </p>
          <p>
            In the Mediterranean, the sustainable management of fisheries resources are a great challenge that cannot be postponed any longer. Today, the fragmentation of the industry hampers the revitalization of this key economic sector, and the attractiveness of the sector for the younger generations are lower than ever.
          </p>
          <p>
            The FISH MED NET project supports Mediterranean enterprises in the fishing sector by focusing on objectives defined in four main themes, among which we find the Development of MSMEs.
          </p>
          <p>
	          The Technology Transfer and Innovation and Professionalization of Youth and Women are also themes addressed by this project, with the aim of revitalizing the fishing sector, making it more attractive to young people, offering training and facilitating access to employment through professional support. 
          </p>
          <p>
            Through new business activities, the project helps fishermen to become Guardians of the sea, contributing to the SDG 14: Conserve and sustainably use oceans, seas and marine resources for sustainable development.
          </p>
        </div>
        <img src={story}/>
      </div>

      <h2>Applying to FISH MED NET</h2>
      <p>
        FISH MED NET aims to reach as many fishing communities as possible. We believe that cooperation among all the involved fishermen is essential for a successful operation and can only benefit if this community takes care of one another. One of the responsibilities of the counseling desk is to provide information on how to become part of this community and become a role model for other fishermen aspiring to conduct their activities in complete harmony with their local community, all while respecting the United Nations' Sustainable Development Goals (SDGs). For more information on where and how to contact the counseling desks, please click <a href="/howto/">here</a>.
      </p>

	    <h2>Sinergies with ENI CBC MED projects</h2>
      <p>
        One of the fundamental principles that the FISH MED NET project strives on is creating a
        cooperative environment among the various stakeholders, by providing the necessary
        visibility of the involved stakeholders to third parties. All of these efforts aim for a better world
        to live in and similarly the project also seeks out synergies with other ENI CBC MED projects
        who share similar goals. In this page it is possible to view the different projects that have
        synergies with FISH MED NET and learn more about what UN SDGs are shared with each
        project.
	    </p>
      <p><a href="/cooperation">See more</a></p>

    	<h2>FishmedNet Training</h2>
	    <p>
        Within the framework of the activities carried out by the FISHMEDNET project, training represents a key tool to improve the skills of companies and develop new opportunities for diversifying fishing activities.
      </p>
      <p><a href="/training">See more</a></p>


	        <h2>Standard Package</h2>
            <p>
	FISH MED NET also explores on how fishers can become entrepreneurs by offering services to the collectivity, click here to learn more.
      </p>
      <p><a href="/standard-package">See more</a></p>

      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default IndexPage
