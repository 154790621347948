import React from "react"
import GoogleMapReact from 'google-map-react'
import { StaticQuery, graphql } from "gatsby"
import { getFisherData } from "../utils"

import marker from "../images/marker.png"
import arrow from "../images/arrow.png"
import over from "../images/map-over.png"

import fishImg from "../images/fish.svg"
import tourismImg from "../images/tourism.svg"
import acquacultureImg from "../images/acquaculture.svg"
import foodImg from "../images/food.svg"
import partnershipImg from "../images/partnership.svg"

const sectors = ['Tourism', 'Acquaculture', 'Food', 'Service to Community']

const sectorLabel = {
  Tourism: "Tourism",
  Acquaculture: "Acquaculture",
  Food: "Food",
  "Service to Community": "Service to Community",
}

const sectorDesc = {
  Tourism: "Hospitality on boats and homes of professional fishermen",
  Acquaculture: "Seafood products raised in confined and human-controlled environments",
  Food: "Catering, direct sales and fish processing",
  "Service to Community": "Environmental services. Waste, sampling, monitoring",
}

const sectorImg = {
  Tourism: tourismImg,
  Acquaculture: acquacultureImg,
  Food: foodImg,
  "Service to Community": partnershipImg,
}

const locations = [
  { lat: 39.638, lon: 8.483 }, // Sardegna sud
  { lat: 40.905, lon: 8.295 }, // Sardegna nord
  { lat: 37.110, lon: 10.0087 }, // Tunisi
  { lat: 40.751, lon: 17.479 }, // Puglia
  { lat: 33.831, lon: 35.544 }, // Beirut
  { lat: 31.483, lon: 34.509 }, // Gaza
  { lat: 45.4, lon: 12.4 }, // Venezia
  { lat: 44, lon: 12.75 }, // San Marino
  { lat: 44.120, lon: 8.596 }, // Genova
  { lat: 42.065,  lon: 9.108 }, // Corsica
  { lat: 35.612,  lon: 10.948 }, // Monastir
  { lat: 39.098,  lon: 16.807}, //Calabria
  { lat: 40.7481233, lon: 14.1235311}, //Campania
];

function locationOf(fisher) {
  let loc = null;
  let dist = Infinity;
  for (const l of locations) {
    const dx = fisher.lon - l.lon;
    const dy = fisher.lat - l.lat;
    const d = dx*dx + dy*dy;
    if (d < dist) {
      loc = l;
      dist = d;
    }
  }
  return loc;
}

function removeDuplicates(arr) {
  return [...new Map(arr.map(v => [JSON.stringify(v), v])).values()];
}

function moveAway(p, q) {
  const dist = 0.1;
  const half = dist / 2;
  const dx = q.lon - p.lon;
  const dy = q.lat - p.lat;
  if (dx === 0 && dy === 0) {
    p.lat -= half;
    q.lat += half;
    return;
  }
  if (dx*dx + dy*dy >= dist*dist) {
    return;
  }
  const angle = Math.atan2(dy, dx);
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const center = { lat: (p.lat+q.lat)/2, lon: (p.lon+q.lon)/2 };
  p.lon = center.lon - half*cos;
  p.lat = center.lat - half*sin;
  q.lon = center.lon + half*cos;
  q.lat = center.lat + half*sin;
}

function makeDistance(fishers) {
  for (let k = 0; k < 10; k++) {
    for (let i = 0; i < fishers.length; i++) {
      const p = fishers[i];
      for (let j = i+1; j < fishers.length; j++) {
        const q = fishers[j];
        moveAway(p, q);
      }
    }
  }
  return fishers;
}

const zoomOut = typeof(window) !== 'undefined' && window.innerWidth <= 700 ? 4 : 5
const zoomIn = typeof(window) !== 'undefined' && window.innerWidth <= 700 ? 8 : 9
const zoomTheshold = (zoomOut + zoomIn) / 2

class FisherMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = { center: [38.7, 18], zoom: zoomOut, sector: 'All' }
  }

  zoomAnimationEnd = zoom => {
    this.setState({ zoom })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            hasuraQuery {
              form_data(where: {form_status_ref_id: {_eq: "d1968f35-62fb-445a-a3df-230fd5a605e0"}}) {
                data
              }
            }
          }
        `}
        render={data => (<div>
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBOIeMHkswV3-iS11f2cJ7WTzT1cVlmSaQ',
                language: 'it',
                region: 'it',
              }}
              center={this.state.center}
              zoom={this.state.zoom}
              options={{
                fullscreenControl: false,
                scrollwheel: false,
                styles: [{ elementType: 'labels', stylers: [{ 'visibility': 'off' }] }],
              }}
              onZoomAnimationEnd={this.zoomAnimationEnd}
            >
              {this.state.zoom <= zoomTheshold ?
                removeDuplicates(
                  getFisherData(data).filter(fisher => (
                    this.state.sector === 'All' ||
                    fisher.activities && fisher.activities.includes(this.state.sector)
                  )).map(locationOf)
                ).map(loc => (
                  <div className="location" lat={loc.lat} lng={loc.lon}
                    onClick={() => this.setState({ center: [loc.lat, loc.lon], zoom: zoomIn })}
                  />
                )) :
                makeDistance(getFisherData(data).filter(fisher => (
                  this.state.sector === 'All' ||
                  fisher.activities && fisher.activities.includes(this.state.sector)
                ))).sort((f, g) => f.lon - g.lon).map(fisher => (
                  <div className="marker" lat={fisher.lat} lng={fisher.lon}>
                    <div>
                      <a href={'/' + fisher.slug} className="marker-box">
                        <span>{fisher.name}</span>
                        <img src={arrow}/>
                      </a>
                    </div>
                    <img src={marker}/>
                  </div>
                ))
              }
            </GoogleMapReact>
            <img src={over}/>
          </div>
          <div id="sectors" className="content-pad big-margin-bottom">
            <div className={this.state.sector === 'All' ? 'sector-selected' : ''}>
              <a onClick={() => this.setState({ sector: 'All' })}>
                <img src={fishImg}/>
                <br/>
                <b>All the proposals</b>
                <br/>
                <span></span>
              </a>
            </div>
            {sectors.map(s =>
              <div className={this.state.sector === s ? 'sector-selected' : ''}>
                <a onClick={() => this.setState({ sector: s })}>
                  <img src={sectorImg[s]}/>
                  <br/>
		              <b>{sectorLabel[s]}</b>
                  <br/>
                  <span>{sectorDesc[s]}</span>
                </a>
              </div>
            )}
          </div>
        </div>)}
      />
    )
  }
}

export default FisherMap
